import logo from './logo.svg';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginForm from './component/Login';
import Member from './component/Member';
import Applications from './component/Applications';
import WorldMap from './component/WorldMap';
import Option1 from './component/Option1';
import Option2 from './component/Option2';
import Option3 from './component/Option3';
import Option4 from './component/Option4';
import Option5 from './component/Option5';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<WorldMap />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/index" element={<Member />} />
        <Route path="/option1" element={<Option1 />} />
        <Route path="/option2" element={<Option2 />} />
        <Route path="/option3" element={<Option3 />} />
        <Route path="/option4" element={<Option4 />} />
        <Route path="/option5" element={<Option5 />} />
        <Route path="/applications" element={<Applications />} />
      </Routes>
    </Router>
  );
}

export default App;
