import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SendIcon from '@mui/icons-material/Send';
import { DataGrid } from '@mui/x-data-grid';
import { Container, Box, CircularProgress } from '@mui/material';
import AppBarWithHamburger from './AppBarWithHamburger';

const API_PATH = process.env.REACT_APP_API_PATH;

const Member = () => {
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [pw, setPw] = useState('');
  const [acc, setAcc] = useState('');
  const [name, setName] = useState('');
  const [point, setPoint] = useState(0);
  const [selectedMember, setSelectedMember] = useState({
    name: '',
    account: '',
    password: '',
    point: 0,
  });
  const navigate = useNavigate();

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_PATH}/admin/user`);
  
      if (response.status === 200) { 
        setRows(response.data.source);
        setLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login', { replace: true });
      }
    }
  };

  const createCustomer = async () => {
    const request = {
      Name:name,
      Account:acc,
      Password:pw,
      Point:point
    }
    try {
      const response = await axios.post(`${API_PATH}/admin/user`, request);
      if (response.status === 200) { 
        setRows(response.data.source);
        setOpen(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        navigate('/login', { replace: true });
      }
    }
  };

  const deleteUser = async (id) => {
    try {
      const response = await axios.delete(`${API_PATH}/admin/user`, {
        params: {
          id: id
        }
      }); 
      if (response.status === 200) {
        setRows(response.data.source);
      }
    } catch (error) {
      console.error('Error deleting user:', error);
      if (error.response && error.response.status === 401) {
        navigate('/login', { replace: true });
      }
    }
  };
  const handleAccChange = (event) => {
    setAcc(event.target.value);
  };
  const handlePwChange = (event) => {
    setPw(event.target.value);
  };
  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handlePointChange = (event) => {
    const value = event.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setPoint(value);
    }
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateClose = () => {
    setUpdateOpen(false);
  };

  const handleEditMemberChange = (field, value) => {
    setSelectedMember((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
  const handleRowDoubleClick = (params) => {
    setSelectedMember(params.row);
    setUpdateOpen(true);
  };

  const handleEditMember = async () => {
    try {
      // 发送 PUT 请求到后端
      const response = await axios.put(
        `${API_PATH}/admin/user`,
        {
          id:selectedMember.id,
          name: selectedMember.name,
          account: selectedMember.account,
          password: selectedMember.password,
          point: selectedMember.point,
        }
      );

      console.log('更新成功:', response.data);
      setRows(response.data.source);
      alert('更新成功');
    } catch (error) {
      console.error('更新失败:', error);
      alert('更新失败，请重试');
    }
  };

  

  useEffect(() => {
    fetchData();
  }, []); 

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'name', headerName: '名子', width: 100 },
    { field: 'account', headerName: '帐号', width: 150 },
    { field: 'point', headerName: '点数', width: 120 },
    { field: 'createDate', headerName: '创建日', width: 150 },
    {
      field: 'Operation',
      headerName: '操作',
      width: 150,
      renderCell: (params) => (
        <>
          <IconButton aria-label="reset" onClick={() => deleteUser(params.row.id)}>
            <DeleteIcon />
          </IconButton>
        </>
  
      ),
    },
  ];
  if(loading) {
    return (
      <>
        <Box sx={{ height: 800, width: '100%',display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
            <CircularProgress />
        </Box>      
      </>
    )
  }

  return (
    <>
        <AppBarWithHamburger />
        <Container>
        <Box sx={{ height: 800, width: '100%', marginTop: 10 }} >
            <Typography variant="h3" gutterBottom style={{display:'flex',justifyContent:'center'}}>
              会员列表
            </Typography>
            <Button variant="contained" endIcon={<SendIcon />} style={{marginBottom:20}} onClick={handleClickOpen}>
                上传
            </Button>
            <DataGrid rows={rows} columns={columns} pageSize={50} rowsPerPageOptions={[5]} onRowDoubleClick={handleRowDoubleClick}/>
            
        </Box>
        </Container>

        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
            >
            <DialogTitle>新增會員</DialogTitle>
                <DialogContent>
                  <TextField
                        required
                        margin="dense"
                        id="name"
                        label="名子"
                        fullWidth
                        variant="standard"
                        value={name}
                        onChange={handleNameChange}
                    />
                    <TextField
                        required
                        margin="dense"
                        id="name"
                        label="帐号"
                        fullWidth
                        variant="standard"
                        value={acc}
                        onChange={handleAccChange}
                    />
                    <TextField
                        required
                        margin="dense"
                        id="name"
                        label="密码"
                        fullWidth
                        variant="standard"
                        value={pw}
                        onChange={handlePwChange}
                    />
                    <TextField
                        required
                        margin="dense"
                        id="name"
                        label="点数"
                        fullWidth
                        variant="standard"
                        value={point}
                        onChange={handlePointChange}
                    />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>取消</Button>
                <Button type="button" onClick={createCustomer}>送出</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>

        <React.Fragment>
        <Dialog open={updateOpen} onClose={handleUpdateClose}>
          <DialogTitle>编辑会员</DialogTitle>
          <DialogContent>
            <TextField
              required
              margin="dense"
              id="name"
              label="名字"
              fullWidth
              variant="standard"
              value={selectedMember.name}
              onChange={(e) => handleEditMemberChange('name', e.target.value)}
            />
            <TextField
              required
              margin="dense"
              id="account"
              label="帐号"
              fullWidth
              variant="standard"
              value={selectedMember.account}
              onChange={(e) => handleEditMemberChange('account', e.target.value)}
            />
            <TextField
              required
              margin="dense"
              id="point"
              label="点数"
              fullWidth
              variant="standard"
              type="number"
              value={selectedMember.point}
              onChange={(e) => handleEditMemberChange('point', e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleUpdateClose}>取消</Button>
            <Button type="button" onClick={handleEditMember}>
              保存
            </Button>
          </DialogActions>
        </Dialog>
        </React.Fragment>

    </>
  );
};

export default Member;
